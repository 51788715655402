/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { environment } from 'environments/environment';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable, ReplaySubject, throwError, timer } from 'rxjs';
import { delay, find, map, retry, retryWhen, switchMap, take, tap, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public _notifications: BehaviorSubject<Notification[]> = new BehaviorSubject([]);
  private api = environment.api_order;

  get notifications$(): Observable<Notification[]> { return this._notifications.asObservable(); }

  constructor(private _httpClient: HttpClient) { }

  getAll(): Observable<Notification[]>
  {
      const delayMs: number = 1000;
      const maxRetries: number = 5;
      const maxTime: number = 12000;
      const currentMs = new Date().getTime();
      return this._httpClient.get<Notification[]>(this.api + '/notifications').pipe(
        timeout(maxTime),
        retryWhen(errors => {
          let retries = 0;
          retries++;
          if (retries >= maxRetries) {
            return throwError('Max attempt to connect to server notifications');
          }
          return timer(delayMs * Math.pow(2, retries));
        }),
        tap((notifications: any) => {
            this._notifications.next(notifications.data);
        })
      );
  }

  markAllAsRead(): Observable<boolean>
  {
    return this.notifications$.pipe(
      take(1),
      switchMap(notifications => this._httpClient.put<boolean>(this.api + '/notifications/allread', {}).pipe(
        map((isUpdated: boolean) => {
            notifications.forEach((_notification, index) => {
                notifications[index].read = true;
            });
            this._notifications.next(notifications);
            return isUpdated;
        })
      ))
    );
  }

  updateQuoteFreightNotification(totalNotify: number): void
  {
    if (totalNotify > 0) {
      const notifications = cloneDeep(this._notifications.value);
      const idx = notifications.findIndex((el: any) => el.id === 'freight99');
      if (notifications.length > 0) {
        if (idx > -1) {
          notifications[idx].title = '<strong class="text-teal-600">COTAÇÃO DE FERETE</strong>';
          notifications[idx].description = (totalNotify === 1 )? 'Exite uma nova cotação de frete': `Existem <strong class="text-teal-600">${totalNotify} novas cotações</strong> de frete`;
          notifications[idx].link = 'cadastro/tabela-frete';
          notifications[idx].time = new Date().toDateString();
          notifications[idx].icon = 'mat_outline:monetization_on';
          notifications[idx].useRouter = true;
          notifications[idx].read = false;
        } else {
          notifications.push({
            id: 'freight99',
            title: '<strong class="text-teal-600">COTAÇÃO DE FERETE</strong>',
            description: (totalNotify === 1 )? 'Exite uma nova cotação de frete': `Existem <strong class="text-teal-600">${totalNotify} novas cotações</strong> de frete`,
            link: 'cadastro/tabela-frete',
            time: new Date().toDateString(),
            icon: 'mat_outline:monetization_on',
            useRouter: true,
            read: false
          });
        }
      } else {
        notifications.push({
          id: 'freight99',
          title: '<strong class="text-teal-600">COTAÇÃO DE FERETE</strong>',
          description: (totalNotify === 1 )? 'Exite uma nova cotação de frete': `Existem <strong class="text-teal-600">${totalNotify} novas cotações</strong> de frete`,
          link: 'cadastro/tabela-frete',
          time: new Date().toDateString(),
          icon: 'mat_outline:monetization_on',
          useRouter: true,
          read: false
        });
      }
      this._notifications.next(notifications);
    }
  }

  update(id: string, notification: Notification): Observable<boolean>
  {
    return this.notifications$.pipe(
      take(1),
      switchMap(notifications => this._httpClient.put<boolean>(this.api + `/notifications/${id}`, {notification}).pipe(
        map((isUpdated: boolean) => {
            notifications.forEach((_notification, index) => {
                notifications[index].read = true;
            });
            this._notifications.next(notifications);
            return isUpdated;
        })
      ))
    );
  }

  delete(id: string): Observable<boolean>
  {
    return this.notifications$.pipe(
      take(1),
      switchMap(notifications => this._httpClient.delete<boolean>(this.api + `/notifications/${id}`).pipe(
        map((isDeleted: boolean) => {
            const index = notifications.findIndex(item => item.id === id);
            notifications.splice(index, 1);
            this._notifications.next(notifications);
            return isDeleted;
        })
      ))
    );
  }


}
